import React, { useState, useEffect, useRef, useCallback } from 'react'
import AppBody from '../AppBody'
import Decimal from 'decimal.js-light'
import './acrossChain.css'
import { serverLink } from '../../store/constants'
import {
  getUserAddress,
  web3,
  execute,
  allowanceLP,
  getErc20Contract,
  getConvertContract,
  getAccount
} from '../../utils/playerAddr'
import Spin from '../Bank/Spin'
import intl from 'react-intl-universal'
import aura from '../../assets/images/acrosschain/aura.png'
import change1 from '../../assets/images/acrosschain/change1.png'
// import change2 from '../../assets/images/acrosschain/change2.png'
import bsc from '../../assets/images/acrosschain/bsc.png'
import ht from '../../assets/images/acrosschain/ht.png'
import record from '../../assets/images/acrosschain/record.png'
import Footer from '../../components/Foot/foot'
// ////
import CurrencySearchModal from '../../components/SearchModal/CurrencySearchModal2.tsx'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import { Select } from 'antd'

const AcrossChain = props => {
  const [flagError, setFlagError] = useState(false)
  const [countdown, setCountdown] = useState(2)
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [currentChainId, setCurrentChainId] = useState('')
  const [currentChainName, setCurrentChainName] = useState('')
  const [config, setConfig] = useState({})
  const [currentConfig, setCurrentConfig] = useState({})
  const [transferAmount, setTransferAmount] = useState(0)
  const [transferWallet, setTransferWallet] = useState('')
  const [quota, setQuota] = useState(-1)
  const [userAddr, setUserAddr] = useState('')
  const [balance, setBalance] = useState(0)
  // //////
  const [tokensArr, setTokensArr] = useState([])
  const [tokens, setTokens] = useState('AURA')
  const [modalOpen, setModalOpen] = useState(false)

  const time = useRef(null)

  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }
  const message = msg => {
    setLoading(false)
    setFlagError(true)
    setMessageError(msg)
    countdownFun()
  }
  const goExchange = () => {
    props.history.push({ pathname: './exchangeHjw' })
  }
  const goRecord = () => {
    props.history.push({ pathname: './record/1' })
  }
  //切换链
  // const changeChain = () => {}
  //所在链余额
  const currentBalance = async () => {
    setLoading(true)
    let balance = 0
    // balance = await execute(userAddr, currentConfig.tokens.AURA.Address)
    balance = await execute(userAddr, currentConfig.tokens[tokens].Address)
    setLoading(false)
    return Math.floor(balance * 100) / 100
  }
  //最大值
  const changeMax = async () => {
    if (userAddr) {
      let balance = await currentBalance()
      console.log('balance', balance)
      setTransferAmount(balance)
      // if (quota != -1) {
      //   if (balance > quota) {
      //     setTransferAmount(quota)
      //   } else {
      //     setTransferAmount(balance)
      //   }
      // } else {
      //   setTransferAmount(balance)
      // }
    }
  }
  //转账金额改变
  const changeTransferAmount = e => {
    e.persist()
    if (e.target.value >= 0) {
      setTransferAmount(e.target.value)
    }
    // if (quota != -1) {
    //   if (e.target.value >= 0) {
    //     if (e.target.value <= quota) {
    //       setTransferAmount(e.target.value)
    //     } else {
    //       setTransferAmount(quota)
    //     }
    //   }
    // } else {
    //   if (e.target.value >= 0) {
    //     setTransferAmount(e.target.value)
    //   }
    // }
  }
  //转账钱包地址
  const changeTransferWallet = e => {
    e.persist()
    setTransferWallet(e.target.value)
  }
  //设置项
  const getConfig = async (id, addr) => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/get-convert-config/${addr == undefined ? '' : addr}
      `)
      ).json()
      if (res.code === 0) {
        for (let i in res.data) {
          if (id === res.data[i].chain_id) {
            // 下拉框选项列表 ////
            // let tokensArr=[]
            // for(var key in res.data[i].tokens){
            //   let obj={
            //     name:key,
            //     symbol:key,
            //   }
            //   tokensArr.push(obj)
            // };
            // setTokensArr(tokensArr)
            setTokensArr(Object.keys(res.data[i].tokens))

            let balance = 0
            // balance = await execute(addr, res.data[i].tokens.AURA.Address)
            balance = await execute(addr, res.data[i].tokens[tokens].Address)
            setLoading(false)
            setBalance(Math.floor(balance * 100) / 100)
            setCurrentConfig(res.data[i])
            setCurrentChainName(i)
            if (i == res.data.auxiliary_chain) {
              setQuota(Number(res.data[i].quota))
            }
          }
        }
        // setConfig(res.data)
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }

  // 打开下拉弹窗////
  const openSelect = () => {
    setModalOpen(true)
  }
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])
  const onCurrencySelect = useCallback(async tokens => {
    setTokens(tokens)
    let balance = await execute(userAddr, currentConfig.tokens[tokens].Address)
    setBalance(Math.floor(balance * 100) / 100)
  })

  const getCollectionToken = async () => {
    setLoading(true)
    const res = await (
      await fetch(`${serverLink}/api/get-convert-currency-data/${userAddr}/${transferWallet}/${transferAmount}/${currentChainName}/${tokens}
        `)
    ).json()
    if (res.code === 0) {
      let data = res.data
      try {
        await getConvertContract(currentConfig.convert_tool_contract)
          .methods.collectionToken(
            // currentConfig.tokens.AURA.Address,
            currentConfig.tokens[tokens].Address,
            data.total_amount,
            data.destroy_amount,
            data.destroy_address,
            data.deadline,
            data.code,
            data.v,
            data.r,
            data.s
          )
          .send({ from: userAddr })
          .then(() => {
            message(intl.get('successfulTransfer'))
            setTransferAmount(0)
            setTransferWallet('')
            getConfig(currentChainId, userAddr)
          })
      } catch (e) {
        message(e.message)
        setLoading(true)
        const res1 = await (
          await fetch(`${serverLink}/api/return-convert-currency-limit/${userAddr}/${transferWallet}/${data.code}
            `)
        ).json()
        if (res.code === 0) {
          getConfig(currentChainId, userAddr)
        } else {
          message(res1.msg)
        }
      }
    } else {
      message(res.msg)
    }
  }
  //连接钱包
  const { ethereum } = window
  const isMetaMask = !!(ethereum && ethereum.isMetaMask)
  const toggleWalletModal = async () => {
    if (isMetaMask) {
      await getAccount()
      getUserAddress().then(r => {
        setUserAddr(r)
      })
    } else {
      message(intl.get('metaMask'))
    }
  }
  //转帐
  const transfer = async () => {
    if (!userAddr) {
      toggleWalletModal()
      return
    }
    if (transferAmount === 0 || transferAmount === '') {
      message(intl.get('pleaseFillta'))
      return
    }
    if (transferAmount > (await currentBalance())) {
      message(intl.get('exceededMax'))
      return
    }
    if (transferWallet === '') {
      message(intl.get('pleaseFillwa'))
      return
    }
    setLoading(true)
    let allowAcount = await allowanceLP(
      userAddr,
      currentConfig.convert_tool_contract,
      // currentConfig.tokens.AURA.Address
      currentConfig.tokens[tokens].Address
    )
    setLoading(false)
    let amount = 90000000000000000000000000000000000000000000000000000000000
    let amount1 = new Decimal(amount).mul(new Decimal(10).pow(5)).toFixed()
    if (transferAmount > Number(allowAcount)) {
      setLoading(true)
      try {
        // await getErc20Contract(currentConfig.tokens.AURA.Address)
        await getErc20Contract(currentConfig.tokens[tokens].Address)
          .methods.approve(currentConfig.convert_tool_contract, amount1)
          .send({ from: userAddr })
          .then(r => {
            message(intl.get('successConTr'))
            getCollectionToken()
          })
        // await approveLP(currentConfig.convert_tool_contract, amount1, userAddr, currentConfig.tokens.AURA.Address)
      } catch (e) {
        message(e.message)
      }
      return
    }
    getCollectionToken()
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlagError(false)
      setMessageError('')
      clearInterval(time.current)
    }
  }, [countdown])

  useEffect(() => {
    web3()
      .eth.net.getId()
      .then(r => {
        setCurrentChainId(r)
        getUserAddress().then(addr => {
          setUserAddr(addr)
          getConfig(r, addr)
        })
      })
  }, [])

  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}
      <AppBody></AppBody>
      <div className="ac-menu">
        <div className="ac-menuCon">
          <div className="ac-menuLeft">
            {
              <div
                className="ac-select"
                onClick={() => {
                  goExchange()
                }}
              >
                {intl.get('exchange')}
              </div>
            }
            <div className="ac-selected">
              <div>{intl.get('acrossChain')}</div>
              <div className="ex-menuLine"></div>
            </div>
          </div>
          <div
            className="ac-lookRecord"
            onClick={() => {
              goRecord()
            }}
          >
            <img src={record} alt="" />
            <span>{intl.get('lookRecord')}</span>
          </div>
        </div>
      </div>

      <div className="ac-changeChain">
        <img className="ac-changeChainIcon" src={change1} />
        <div className="ac-fromTo">
          <div>From</div>
          <img src={currentChainName === 'heco' ? ht : bsc} />
          <div>{currentChainName === 'heco' ? 'HECO' : 'Binance Smart Chain'} </div>
        </div>
        <div className="ac-fromTo">
          <div>To</div>
          <img src={currentChainName === 'heco' ? bsc : ht} />
          <div>{currentChainName === 'heco' ? 'Binance Smart Chain' : 'HECO'}</div>
        </div>
      </div>
      <div className="ac-transfer">
        {/* 改下拉框///// */}
        <div
          className="ac-currency"
          onClick={() => {
            openSelect()
          }}
        >
          <div className="ac-currency_box">
            {/* <img src={aura} />
            <div>AURA</div> */}

            <img
              size={'24px'}
              alt={`logo`}
              style={{ float: 'left', margin: '0px 10px 0 0' }}
              src={'/images/token/' + tokens + '.png'}
            />
            <div>{tokens}</div>
          </div>
          <DropDown />
        </div>

        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={tokens}
          tokens={tokensArr}
          // otherSelectedCurrency={otherCurrency}
          // showCommonBases={showCommonBases}
        />

        <div className="ac-transferAmount">
          <input
            inputMode="decimal"
            type="number"
            pattern="^(0x[a-fA-F0-9]{40})$"
            value={transferAmount}
            onChange={e => changeTransferAmount(e)}
          />
          <div
            onClick={() => {
              changeMax()
            }}
          >
            MAX
          </div>
        </div>
        <div style={{ color: '#E5CF80', fontSize: '11px', marginTop: '8px' }}>
          {intl.get('crossChainBalance')}：{balance} {tokens.toUpperCase()}
        </div>
        <div className="ac-wallet">
          <input
            placeholder={intl.get('pleaseFillwa')}
            value={transferWallet}
            onChange={e => changeTransferWallet(e)}
          />
        </div>
        <div className="ac-handlingFee">
          <div>
            {/* {intl.get('serviceFee')} {config.convert_service_rate * 100}% */}
            {intl.get('serviceFee')}{' '}
            {!!currentConfig.tokens ? currentConfig.tokens[tokens].ConvertServiceRate * 100 : ''}%
          </div>
          {/* <div>{(transferAmount * config.convert_service_rate).toFixed(4)}</div> */}
          <div>
            {!!currentConfig.tokens ? (transferAmount * currentConfig.tokens[tokens].ConvertServiceRate).toFixed(4) : 0}
          </div>
        </div>
        {/* <div className="ac-handlingFee">
          <div>
            {intl.get('destroyRate')} {currentConfig.destroy_rate * 100}%
          </div>
          <div>{(transferAmount * currentConfig.destroy_rate).toFixed(4)}</div>
        </div> */}
        <div className="ac-get">
          <div>{intl.get('estimatedA')}</div>
          {/* <div>{(transferAmount * (1 - config.convert_service_rate - currentConfig.destroy_rate)).toFixed(4)}</div> */}
          <div>
            {!!currentConfig.tokens
              ? (
                  transferAmount *
                  (1 - currentConfig.tokens[tokens].ConvertServiceRate - currentConfig.destroy_rate)
                ).toFixed(4)
              : 0}
          </div>
        </div>
        <div
          className="ac-transferButton"
          style={{ display: currentChainId === 128 ? 'none' : 'block' }}
          onClick={() => {
            transfer()
          }}
        >
          {userAddr ? intl.get('acrossChainTr') : intl.get('connectWallet')}
        </div>
      </div>
      <Footer url={window.location.href} />
      <div className="modal" style={{ display: flagError ? 'flex' : 'none' }}>
        <div className="modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div>
    </>
  )
}

export default AcrossChain
