import React, { useState, useEffect, useRef } from 'react'
import AppBody from '../AppBody'
import Decimal from 'decimal.js-light'
import './acrossChain.css'
import { serverLink } from '../../store/constants'
import {
  hjwHecoToBsc,
  getUserAddress,
  execute,
  allowanceLP,
  getErc20Contract,
  getAccount,
  web3
} from '../../utils/playerAddr'
import Spin from '../Bank/Spin'
import intl from 'react-intl-universal'
import bsc from '../../assets/images/acrosschain/bsc.png'
import ht from '../../assets/images/acrosschain/ht.png'
import change1 from '../../assets/images/acrosschain/change1.png'
import record from '../../assets/images/acrosschain/record.png'
import Footer from '../../components/Foot/foot'

const ExchangeHJW = props => {
  const [data, setData] = useState({})
  const [currentChainId, setCurrentChainId] = useState('')
  const [flagError, setFlagError] = useState(false)
  const [countdown, setCountdown] = useState(2)
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [transferAmount, setTransferAmount] = useState('')
  const [userAddr, setUserAddr] = useState('')
  const [balance, setBalance] = useState(0)
  const [getNumber, setGetNumber] = useState(0) //预计实际可得
  const time = useRef(null)

  //倒计时
  const countdownFun = () => {
    setCountdown(2)
    time.current = setInterval(() => {
      setCountdown(countdown => countdown - 1)
    }, 1000)
  }
  const message = msg => {
    setLoading(false)
    setFlagError(true)
    setMessageError(msg)
    countdownFun()
  }
  const goAcrossChain = () => {
    props.history.push({ pathname: './acrossChain' })
  }
  const goRecord = () => {
    props.history.push({ pathname: './recordHjw' })
  }
  //余额
  const currentBalance = async (userAddr, addr) => {
    setLoading(true)
    let balance = 0
    balance = await execute(userAddr, addr)
    setLoading(false)
    return Math.floor(balance * 10000) / 10000
  }
  //获取基础配置,r当前链id
  const getData = async (address, r) => {
    setLoading(true)
    try {
      const res = await (
        await fetch(`${serverLink}/api/token-release/get-base-info/${address}
      `)
      ).json()
      if (res.code === 0) {
        setLoading(false)
        setData(res.data)
        if (r === res.data.investment_chain_id) {
          setBalance(await currentBalance(address, res.data.investment_currency_contract_address))
        }
      } else {
        message(res.msg)
      }
    } catch (e) {
      message(e.message)
    }
  }

  //预计实际可得改变
  const getNumberChange = async num => {
    // console.log(data.investment_ratio)
    setGetNumber(num * data.investment_ratio)
  }
  //最大值
  const changeMax = () => {
    if (userAddr) {
      setTransferAmount(balance)
      getNumberChange(balance)
    }
  }
  //转账金额改变
  const changeTransferAmount = async e => {
    e.persist()
    if (e.target.value >= 0) {
      setTransferAmount(e.target.value)
      getNumberChange(e.target.value)
    }
  }

  const getCollectionToken = async () => {
    setLoading(true)
    let num = new Decimal(Number(transferAmount)).mul(new Decimal(10).pow(18)).toFixed()
    try {
      await hjwHecoToBsc(data.investment_contract_address, num, data.investment_currency_contract_address, userAddr)
      setBalance(await currentBalance(userAddr, data.investment_currency_contract_address))
      setTransferAmount('')
      setGetNumber(0)
      message(intl.get('exSuccess'))
    } catch (e) {
      message(e.message)
    }
  }
  //连接钱包
  const { ethereum } = window
  const isMetaMask = !!(ethereum && ethereum.isMetaMask)
  const toggleWalletModal = async () => {
    if (isMetaMask) {
      await getAccount()
      getUserAddress().then(r => {
        setUserAddr(r)
      })
    } else {
      message(intl.get('metaMask'))
    }
  }
  //兑换
  const transfer = async () => {
    if (!userAddr) {
      toggleWalletModal()
      return
    }
    if (currentChainId !== data.investment_chain_id) {
      message(intl.get('goToHecoEx'))
      return
    }
    if (transferAmount === 0 || transferAmount === '') {
      message(intl.get('inputExAm'))
      return
    }
    if (transferAmount > balance) {
      message(intl.get('exMax'))
      return
    }
    setLoading(true)
    let allowAcount = await allowanceLP(
      userAddr,
      data.investment_contract_address, //合约地址
      data.investment_currency_contract_address //代币地址
    )
    setLoading(false)
    let amount = 90000000000000000000000000000000000000000000000000000000000
    let amount1 = new Decimal(amount).mul(new Decimal(10).pow(5)).toFixed()
    if (transferAmount > Number(allowAcount)) {
      setLoading(true)
      try {
        await getErc20Contract(data.investment_currency_contract_address)
          .methods.approve(data.investment_contract_address, amount1)
          .send({ from: userAddr })
          .then(r => {
            message(intl.get('authorSContinueE'))
            getCollectionToken()
          })
      } catch (e) {
        message(e.message)
      }
      return
    }
    getCollectionToken()
  }
  useEffect(() => {
    if (countdown < 0) {
      setFlagError(false)
      setMessageError('')
      clearInterval(time.current)
    }
  }, [countdown])

  useEffect(() => {
    web3()
      .eth.net.getId()
      .then(r => {
        setCurrentChainId(r)
        getUserAddress().then(addr => {
          setUserAddr(addr)
          getData(addr, r)
        })
      })
  }, [])

  return (
    <>
      {loading ? (
        <div className="spin-bg">
          <Spin />
        </div>
      ) : null}
      <AppBody></AppBody>
      <div className="ac-menu">
        <div className="ac-menuCon">
          <div className="ac-menuLeft">
            <div className="ac-selected">
              <div>{intl.get('exchange')}</div>
              <div className="ex-menuLine"></div>
            </div>
            <div
              className="ac-select"
              style={{ display: currentChainId === 128 ? 'none' : 'block' }}
              onClick={() => {
                goAcrossChain()
              }}
            >
              <div>{intl.get('acrossChain')}</div>
              {/* <div className="ex-menuLine"></div> */}
            </div>
          </div>
          <div
            className="ac-lookRecord"
            onClick={() => {
              goRecord()
            }}
          >
            <img src={record} alt="" />
            <span>{intl.get('lookRecord')}</span>
          </div>
        </div>
      </div>

      <div className="ac-changeChain">
        <img className="ac-changeChainIcon" src={change1} />
        <div className="ac-fromTo">
          <div>From</div>
          <img src={ht} />
          <div>HECO</div>
        </div>
        <div className="ac-fromTo">
          <div>To</div>
          <img src={bsc} />
          <div>Binance Smart Chain</div>
        </div>
      </div>
      <div className="ac-transfer">
        <div className="ac-transferAmount" style={{ marginTop: 16 }}>
          <input
            inputMode="decimal"
            type="number"
            pattern="^(0x[a-fA-F0-9]{40})$"
            value={transferAmount}
            placeholder={intl.get('inputE')}
            onChange={e => changeTransferAmount(e)}
          />
          <div
            onClick={() => {
              changeMax()
            }}
          >
            MAX
          </div>
        </div>
        <div style={{ color: '#E5CF80', fontSize: '11px', marginTop: '8px' }}>
          {intl.get('currentHave')}HJW：{balance}
        </div>
        <div className="ac-get" style={{ marginTop: 32 }}>
          <div>{intl.get('actuallyC')}</div>
          <div>{Math.floor(getNumber * 10000) / 10000}</div>
        </div>
        <div
          className="ac-transferButton"
          onClick={() => {
            transfer()
          }}
        >
          {userAddr ? intl.get('exchange') : intl.get('connectWallet')}
        </div>
      </div>
      <Footer url={window.location.href} />
      <div className="modal" style={{ display: flagError ? 'flex' : 'none' }}>
        <div className="modalContent">
          <div style={{ color: 'red', fontSize: 12 }}>{messageError}</div>
        </div>
      </div>
    </>
  )
}

export default ExchangeHJW
